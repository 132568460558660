import React, { useEffect, useState } from 'react';

const Marquee = ({ hoveredButton }) => {
  const [logoSet, setLogoSet] = useState([]);
  const [marqueeDuration, setMarqueeDuration] = useState(0);

  const combinedArray = [
    { image: "brandsLogos/justins_white_no_black.png", category: "category1" },
    { image: "brandsLogos/LemonPerfect.png", category: "category1" },
    { image: "brandsLogos/kin_white_no_black.png", category: "category1" },
    { image: "brandsLogos/raos_white_no_black.png", category: "category1" },
    { image: "brandsLogos/olipop_logo_white.webp", category: "category1" },
    { image: "brandsLogos/stur_white_no_black.png", category: "category1" },
    { image: "brandsLogos/mosh_white_transparent.png", category: "category1" },
    { image: "brandsLogos/MagicSpoon.png", category: "category1" },
    { image: "brandsLogos/ily_transparent.png", category: "category1" },
    { image: "brandsLogos/made-by-nacho-logo-white-616x194.avif", category: "category1" },
    { image: "brandsLogos/serenity_white_x.png", category: "category1" },
    { image: "brandsLogos/necessaire_white_x.png", category: "category2" },
    { image: "brandsLogos/cocokind-logo-white-x.png", category: "category2" },
    { image: "brandsLogos/davids_white_no_black.png", category: "category2" },
    { image: "brandsLogos/starface_white_x.png", category: "category2" },
    { image: "brandsLogos/bread_white.webp", category: "category2" },
    { image: "brandsLogos/curie_white_x.png", category: "category2" },
    { image: "brandsLogos/mtn_white_x.png", category: "category3" },
    { image: "brandsLogos/Bare_Performance_white_x.png", category: "category3" },
    { image: "brandsLogos/levels_white_no_black.png", category: "category3" },
    { image: "brandsLogos/create_white_x.png", category: "category3" },
    { image: "brandsLogos/happy_viking_white.webp", category: "category3" },
    { image: "brandsLogos/huel_white_x.png", category: "category3" },
    { image: "brandsLogos/dose_white_x.png", category: "category3" },
    { image: "brandsLogos/blume_white_x.png", category: "category3" },
  ];

  useEffect(() => {
    const category = hoveredButton === "button1" ? "category1" :
      hoveredButton === "button2" ? "category2" :
        hoveredButton === "button3" ? "category3" :
          null;

    const newLogoSet = category
      ? combinedArray.filter(logo => logo.category === category)
      : combinedArray;

    setLogoSet(newLogoSet);

    // Update the duration based on the number of items in the logo set
    const duration = newLogoSet.length > 0 ? Math.max(5, newLogoSet.length * 1) : 10;
    setMarqueeDuration(duration);

  }, [hoveredButton]);

  const getBackgroundColorClass = () => {
    switch (hoveredButton) {
      case "button1":
        return "bg-blue-500"; 
      case "button2":
        return "bg-green-500"; 
      case "button3":
        return "bg-red-500"; 
      default:
        return "bg-orange-500"; 
    }
  };

  return (
    <div className="relative z-20">
      <div className={`${getBackgroundColorClass()} inline-block relative w-full overflow-hidden`}>
        <div className={`marquee relative flex flex-row flex-no-wrap`}>
          {/* Repeat the logo set to create the marquee effect */}
          {Array(4).fill().map((_, index) => (
            <div
              key={index}
              className="marqueeLine w-full"
              style={{
                animationDuration: `${marqueeDuration}s`, // Apply the dynamic duration here
              }}
            >
              {logoSet.map((logo, logoIndex) => (
                <img
                  key={logoIndex}
                  className="w-20 marqueeItem object-contain"
                  src={`${logo.image}`}
                  alt={`Logo ${logoIndex}`}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
